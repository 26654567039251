import React from 'react'
import DashboardWrapper from '../../components/DashboardWrapper'
import Breadcrumbs from '../../parts/Breadcrumbs'
import { useParams } from 'react-router-dom'


interface Params {
  id: string; 
}

const BidView = () => {
  const { id }: Params = useParams();
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Bids' />
        {/* body */}
        
      </>
    </DashboardWrapper>
  )
}

export default BidView