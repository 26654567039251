import { Link } from "react-router-dom"
import { ROUTES } from "../../../routes/routes"
import './style.css'

const ReportsMenuItemList = () => {
    const BusinessOverViewList = [
        {
            name: 'Balance Sheet',
            desc: 'What you own (assets), what you owe (liabilities), and what you invested (equity)',
            path: ROUTES.BALANCESHEET
        },
        {
            name: 'Balance Sheet Comparison',
            desc: 'What you own (assets), what you owe (liabilities), and what you invested (equity) compared to last year',
            path: ROUTES.BSCOMPARISONREPORT
        },
        {
            name: 'Balance Sheet Summary',
            desc: 'A summary of what you own (assets), what you owe (liabilities) and what you invested (equity)',
            path: '#'
        },
        {
            name: 'Balance Sheet Detail',
            desc: 'A detailed view of what you own (assets), what you owe (liabilities) and what you invested (equity)',
            path: '#'
        },
        {
            name: 'Custom Summary Report',
            desc: 'A report you build from scratch. With more options to customize',
            path: '#'
        },
        {
            name: 'Profit and Loss as % of total income',
            desc: 'Your expenses as a percentage of your total income',
            path: '#'
        },
        {
            name: 'Profit and Loss Comparison',
            desc: 'Your income, expenses, and net income (profit or loss) compared to last year',
            path: ROUTES.ISCOMPARISONREPORT
        },
        {
            name: 'Profit and Loss Detail',
            desc: 'Profit and Loss Detail',
            path: '#'
        },
        {
            name: 'Profit and Loss year-to-date comparison',
            desc: 'Your income, expenses, and net income (profit and loss) compared to this year so far.',
            path: '#'
        },
        {
            name: 'Profit and Loss trailing 12 months',
            desc: 'Your income, expenses, and net income (profit and loss). Trailing 12 months.',
            path: '#'
        },
        {
            name: 'Profit and Loss',
            desc: 'Your income, expenses, and net income (profit and loss). Also called an income statement.',
            path: ROUTES.INCOMESTATEMENT
        },
        {
            name: 'Statement of cash flows',
            desc: 'Cash flowing in and out from sales and expenses (operating activities), investments, and financing.',
            path: '#'
        },
        {
            name: 'Statement of changes in equity',
            desc: 'Statement of changes in equity.',
            path: '#'
        },
    ]

    const BookKeepingList = [
        {
            name: 'Account List',
            desc: 'The name, type and balance for each account in your chart of accounts.',
            path: '#'
        },
        {
            name: 'General Ledger',
            desc: 'The beginning balance, transactions and total for each account in your charts of accounts.',
            path: '#'
        },
        {
            name: 'Journal',
            desc: 'The debits and credits for each transaction, listed by date.',
            path: '#'
        },
        {
            name: 'Account history',
            desc: 'Account history.',
            path: '#'
        },
        {
            name: 'Recent Transactions',
            desc: 'Transactions you created or edited in the last 4 days.',
            path: '#'
        },
        {
            name: 'Trial Balance',
            desc: 'Trial balance',
            path: ROUTES.TRIALBALANCE
        },
        {
            name: 'Schedules',
            desc: 'Schedules',
            path: ROUTES.SCHEDULES
        },
        {
            name: 'Transaction Detail by Account',
            desc: 'Transactions and total for each account in your chart of accounts',
            path: '#'
        },
        {
            name: 'Transaction List by Date',
            desc: 'A list of all your transactions, ordered by date.',
            path: '#'
        },
    ]
    return (
        <div className='card card-statistics p-5 mb-20'>
            <table>
                <th><h4>Business Overview</h4></th>
                <tbody>
                    <tr>
                        <td>
                            {BusinessOverViewList.slice(0, 6).map((item, index) => (
                                <div key={index}>
                                    <Link to={item.path}><h6 className="name">{item.name}</h6></Link>
                                    <section>{item.desc}</section>
                                </div>
                            ))}
                        </td>
                        <td>
                            {BusinessOverViewList.slice(6, 13).map((item, index) => (
                                <div key={index}>
                                    <Link to={item.path}><h6 className="name">{item.name}</h6></Link>
                                    <section>{item.desc}</section>
                                </div>
                            ))}
                        </td>
                    </tr>
                </tbody>
            </table>
            <table>
                <th><h4>Book Keeping</h4></th>
                <tbody>
                <tr>
                    <td>
                        {BookKeepingList.slice(0, 4).map((item, index) => (
                            <div key={index}>
                                <Link to={item.path}><h6 className="name">{item.name}</h6></Link>
                                <section>{item.desc}</section>
                            </div>
                        ))}
                    </td>
                    <td>
                            {BookKeepingList.slice(4, 8).map((item, index) => (
                                <div key={index}>
                                    <Link to={item.path}><h6 className="name">{item.name}</h6></Link>
                                    <section>{item.desc}</section>
                                </div>
                            ))}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default ReportsMenuItemList