import React from 'react'
import DashboardWrapper from '../../../components/DashboardWrapper'
import Breadcrumbs from '../../../parts/Breadcrumbs'
import ImplementingPartnersDashboard from '../../../components/project/dashboard/Funders'


const ImplementingPartners = () => {
  return (
    <DashboardWrapper>
      <>
        <Breadcrumbs title='Implementing Partners' />
        <ImplementingPartnersDashboard />

      </>
    </DashboardWrapper>
  )
}

export default ImplementingPartners