/* eslint-disable indent */
const HOME = "/dashboard";
const LOGIN = "/login";
const DASHBOARD = "/";
const MOBILEAPP = "/app";
const STORIES = "/app/stories";
const VIEWSTORY = "/app/stories/view/:id";
const TIPS = "/app/tips";
const FACTS = "/app/facts";
const VIEWFACTS = "/app/facts/view/:id";
const DOCTOR = "/doctor";
const CLINICS = "/clinics";
const CLINICDETAILS = "/clinics/view/:id";
const CLINICDOCTORS = "/clinics/view/:id/doctors";
const CLINICAPPOINTMENTS = "/clinics/view/:id/appointments";
const CLINICTREATMENTS = "/clinics/view/:id/treatments";
const CLINICPATIENTS = "/clinics/view/:id/patients";
const APPOINTMENTS = "/doctor/appointments";
const TREATMENTS = "/doctor/treatments";
const VIEWTREATMENT = "/doctor/treatments/view/:id";
const PATIENTS = "/doctor/patients";
const PROJECTS = "/projects";
const IMPLEMENTINGPARTNERS = "/projects/implementing-partners"
const PROJECTCATEGORIES = "/projects/projects-categories"
const ACTIVITIES = "/projects/activities";
const TEAMS = "/projects/teams";
const CATEGORIES = "/settings/projects/categories";
const VIEWCATEGORIES = "/settings/projects/categories/view/:id";
const PROGRAMS = "/settings/projects/programs";
const VIEWPROGRAM = "/settings/projects/programs/view/:id";
const PROJECTDETAILS = "/projects/view/:id";
const ACTIVITYDETAILS = "/projects/activities/:id";
const REPORTS = "/reports";
const REPORTSVIEW = "/reports/view/:id"; // maybe removed later
const REPORTSVIEWCLINIC = "/reports/clinic/:id";
const REPORTSVIEWPROJECT = "/reports/project/:id";
const REPORTSVIEWEMPLOYEE = "/reports/employee/:id";
const ACCOUNTTRANSACTIONS = "/reports/transaction/:id";
const BUDGETCOMPARISONDETAILS = "/reports/budget-comparison/:id";
const HR = "/hr";
const EMPLOYEES = "/hr/employees";
const ADDEMPLOYEE = "/hr/employees/add";
const EMPLOYEEDETAILS = "/hr/employees/view/:id";
const LEAVEMANAGEMENT = "/hr/leave-management";
const LEAVEMANAGEMENTDETAILS = "/hr/leave-management/view/:id";
const PAYROLLMANAGEMENT = "/hr/payroll-management";
const VIEWPERSONALPAYROLL = "/hr/payroll-management/view/:id";
const ADDPAYROLL = "/hr/payroll-management/add";
const GENERATEPAYROLL = "/hr/payroll-management/generate";
const STAFFPERFORMANCE = "/hr/performance";
const TRAINING = "/hr/training";
const ACCOUNTING = "/accounting";
const TRANSACTIONS = "/accounting/transactions";
const INCOMESTATEMENT = "/reports/accounting/income-statement";
const BALANCESHEET = "/reports/accounting/balancesheet";
const SCHEDULES = "/reports/accounting/schedules";
const CASHFLOW = "/reports/accounting/cash-flow";
const TRIALBALANCE = "/reports/accounting/trail-balance";
const BUDGETREPORT = "/reports/accounting/budget-report";
const PAYROLLREPORT = "/reports/accounting/payroll-report";
const EQUITY = "/reports/accounting/equity";
const REQUISITIONS = "/procurement/requisitions";
const REQUISITIONDETAILS = "/procurement/requisitions/view/:id";
const CASHREQUISITIONS = "/cash-requisitions";
const PURCHASEORDER = "/procurement/purchase-order";
const PURCHASEORDERDETAILS = "/procurement/purchase-order/view/:id";
const BIDANALYSIS = "/procurement/bid-analysis";
const QUOTATION = "/procurement/quotation";
const GOODSRECIEVED = "/procurement/goods-received";
const GOODSRECIEVEDDETAILS = "/procurement/goods-received/view/:id";
const COA = "/accounting/coa";
const VIEWQUOTATION = '/procurement/quotation/view/:id'
const MONTHLYACTIVITES = '/hr/monthly-activities';
const SUBCATEGORIES = "/accounting/sub-categories";
const COADETAILS = "/accounting/coa/details/:id";
const PRICING = "/accounting/pricing";
const INVOICES = "/sales/invoices";
const TAXES = "/accounting/taxes";
const BILLING = "/billing";
const BUDGET = "/accounting/budget";
const BUDGETDETAILS = "/accounting/budget/view/:id";
const STOCKS = "/stocks";
const STOCKPRODUCTS = "/stocks/products";
const STOCKSUPPLIERS = "/stocks/suppliers";
const STOCKOUTGOING = "/stocks/out-going";
const STOCKBRANDS = "/stocks/brand";
const STOCKCATEGORIES = "/stocks/categories";
const STOCKSETTINGS = "/stocks/settings";
const GRANTAPPLICATION = "/non-profits/applications";
const NONPROFITS = "/non-profits";
const MEMBERSHIP = "/non-profits/membership";
const VOLUNTEERS = "/non-profits/volunteers";
const DONORS = "/non-profits/donors";
const PROCUREMENT = "/procurement";
const ASSETS = "/assets";
const ASSETSTYPES = "/settings/assets/types";
const ASSETSINCOME = "/assets/income";
const ASSETSEXPENSES = "/assets/expenses";
const ASSETSVIEW = "/assets/view/:id";
const CUSTOMERS = "/customers";
const CUSTOMERREQUISITIONS = "/customers/requisitions";
const BSCOMPARISONREPORT = "/balancesheet-comparison-report"
const SETTINGS = "/settings";
const MYPROFILE = "/settings/my-profile";
const ORGANISATION = "/settings/organisation";
const LOCATION = "/settings/location";
const ROLES = "/settings/roles";
const DEPARTMENTS = "/settings/department";
const USERS = "/settings/users";
const ACTIVITYTEAMROLES = "/settings/activities/roles";
const ACTIVITYPARAMETERS = "/settings/activities/parameters";
const APPROVINGCOMMITTEE = "/settings/committee/approving";
const REVIEWINGCOMMITTEE = "/settings/committee/reviewing";
const FUNDERS = "/settings/funders";
const UNITS = "/settings/units";
const PAYMENTTYPES = "/settings/payment-types";
const ANNOUNCEMENTS = "/announcements";
const VIEWANNOUNCEMENT = "/announcements/view/:id";
const VIEWTRANSACTIONDETAILS = "/accounting/transactions/:id";
const RECEIVING = "/reports/receiving";
const ISCOMPARISONREPORT = "/report/incomestatement-comparison-report"
//new
const HRSCHEDULES = "/hr/schedules";
const TIMESHEETS = "/hr/TimeSheet";
const REPORTSMENU = "/reports_menu"
const PROPOSALS = "/sales/proposals"
const ESTIMATES = "/sales/estimates"
const PAYMENTS = "/sales/payments"
const CREDITNOTES = "/sales/credit-notes"
const ITEMS = "/sales/items"
const BIDS = "/procurement/bids"
const BIDSTAGES = "/procurement/bid-stages"

export const ROUTES = {
  BIDSTAGES,
  BIDS,
  PROPOSALS,
  ESTIMATES,
  PAYMENTS,
  CREDITNOTES,
  ITEMS,
  VIEWQUOTATION,
  ISCOMPARISONREPORT,
  HOME,
  REQUISITIONS,
  REQUISITIONDETAILS,
  COA,
  PRICING,
  INVOICES,
  TAXES,
  BILLING,
  STOCKS,
  STOCKSETTINGS,
  TRAINING,
  GRANTAPPLICATION,
  MEMBERSHIP,
  VOLUNTEERS,
  DONORS,
  PROCUREMENT,
  ASSETSEXPENSES,
  ASSETSINCOME,
  LOGIN,
  MYPROFILE,
  ROLES,
  DEPARTMENTS,
  ORGANISATION,
  LOCATION,
  DOCTOR,
  ACCOUNTING,
  ASSETS,
  HR,
  NONPROFITS,
  SETTINGS,
  MOBILEAPP,
  ADDPAYROLL,
  VIEWPERSONALPAYROLL,
  USERS,
  ADDEMPLOYEE,
  ASSETSTYPES,
  STOCKPRODUCTS,
  DASHBOARD,
  STOCKSUPPLIERS,
  STOCKOUTGOING,
  STOCKBRANDS,
  STOCKCATEGORIES,
  CLINICS,
  CLINICDETAILS,
  TRANSACTIONS,
  INCOMESTATEMENT,
  BALANCESHEET,
  STORIES,
  CLINICDOCTORS,
  CLINICAPPOINTMENTS,
  CLINICTREATMENTS,
  CLINICPATIENTS,
  REPORTSVIEWCLINIC,
  REPORTSVIEWPROJECT,
  REPORTSVIEWEMPLOYEE,
  ACTIVITYTEAMROLES,
  ACTIVITYPARAMETERS,
  TIPS,
  ASSETSVIEW,
  APPROVINGCOMMITTEE,
  REVIEWINGCOMMITTEE,
  FUNDERS,
  GOODSRECIEVED,
  GOODSRECIEVEDDETAILS,
  BUDGETDETAILS,
  FACTS,
  BUDGET,
  CASHREQUISITIONS,
  UNITS,
  ACCOUNTTRANSACTIONS,
  GENERATEPAYROLL,
  APPOINTMENTS,
  ANNOUNCEMENTS,
  VIEWANNOUNCEMENT,
  PURCHASEORDER,
  PURCHASEORDERDETAILS,
  BIDANALYSIS,
  QUOTATION,
  TREATMENTS,
  PAYMENTTYPES,
  PATIENTS,
  ACTIVITIES,
  PROJECTS,
  TEAMS,
  CATEGORIES,
  PROGRAMS,
  PROJECTDETAILS,
  ACTIVITYDETAILS,
  VIEWPROGRAM,
  REPORTS,
  REPORTSVIEW,
  EMPLOYEES,
  LEAVEMANAGEMENT,
  LEAVEMANAGEMENTDETAILS,
  VIEWCATEGORIES,
  EMPLOYEEDETAILS,
  PAYROLLMANAGEMENT,
  STAFFPERFORMANCE,
  VIEWSTORY,
  VIEWFACTS,
  VIEWTREATMENT,
  VIEWTRANSACTIONDETAILS,
  COADETAILS,
  BUDGETCOMPARISONDETAILS,
  SUBCATEGORIES,
  SCHEDULES,
  CASHFLOW,
  BUDGETREPORT,
  PAYROLLREPORT,
  TRIALBALANCE,
  EQUITY,
  CUSTOMERS,
  RECEIVING,
  CUSTOMERREQUISITIONS,
  MONTHLYACTIVITES,
  HRSCHEDULES,
  TIMESHEETS,
  IMPLEMENTINGPARTNERS,
  PROJECTCATEGORIES,
  REPORTSMENU,
  BSCOMPARISONREPORT,
};
