import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { doGet } from "../api";
import { RootState } from "../redux/app/store";
import { setBidStages, setSelectedBidStage } from '../redux/reducer/bids';

const useBidStages = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    bidStage, bidStages
  } = useSelector(
    (state: RootState) => state.bidStages,
  );

  useEffect(() => {

    if (bidStages.length > 0) {
      setLoading(false);
      return;
    }

    doGet('bidStages').then((response) => {
      if (response.status === 200) {
        dispatch(setBidStages(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    setLoading(true);
    doGet('bid_stages').then((response) => {
      if (response.status === 200) {
        dispatch(setBidStages(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getBidStage = (id: number) => {
    setLoading(true);
    doGet('bid_stages/' + id).then((response) => {
      if (response.status === 200) {
        dispatch(setSelectedBidStage(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  const getBidStages = () => {
    setLoading(true);
    doGet('bid_stages').then((response) => {
      if (response.status === 200) {
        dispatch(setBidStages(response.data.data));
      }
    }).finally(() => {
      setLoading(false);
    })
  }

  return { loading, refresh, bidStage, bidStages, getBidStage, getBidStages }
}

export default useBidStages