import React, { useEffect, useState } from 'react'
import { TableColumn } from 'react-data-table-component';
import useRequisition from '../../../hooks/useRequisition';
import { formatBillStatus, formatRequisitionSelectData } from '../../../utils/helpers';
import ConfirmDelete from '../../global/ConfirmDelete';
import ModalSwitcher from '../../global/ModalSwitcher';
import MyDataTable from '../../global/MyDataTable';
import { EllipsisLoader } from '../../global/MyLoaders';
import AddBidAnalysis from './AddBidAnalysis';
import EditBidAnalysis from './EditBidAnalysis';
import useQuotations from '../../../hooks/useQuotations';
import { Link } from "react-router-dom";
import InputSelect from '../../global/InputSelect';

const ListBidAnalysis = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModal, setOpenModal] = useState(0);
  const [selectedId, setSelectedId] = useState(0);
  const { loading, requisitions, getBidAnalysis } = useRequisition();
  const { quotations, refresh } = useQuotations();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => getBidAnalysis(), []);

  const columns: TableColumn<any>[] = [
    {
      id: 1,
      name: "ID",
      selector: (row) => row.id,
      reorder: true,
      sortable: true,
    },
    {
      id: 2,
      name: "Purchase Request",
      selector: (row) => row.purchase_requisition,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      id: 3,
      name: "Vendor",
      selector: (row) => row.vendor_id,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      id: 4,
      name: "Issue Date",
      selector: (row) => row.issue_date,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    }, 
    {
      id: 5,
      name: "Expiry Date",
      selector: (row) => row.expiry_date,
      reorder: true,
      sortable: true,
      grow: 2,
      wrap: true,
    },
    {
      id: 6,
      name: "Status",
      selector: (row) => <span className={`badge ${formatBillStatus(row.status_id).className}`}> <i className={`fa ${formatBillStatus(row.status_id).icon}`}></i> {formatBillStatus(row.status_id).title}</span>,
      reorder: true,
      sortable: true,
      grow: 1,
      wrap: true,
    },
    {
      button: true,
      cell: (row) => (
        <div className='row'>
          <Link
            className="btn btn-sm btn-info mr-1"
            to='#'
          >
            <i className="fa fa-eye"></i>
          </Link>
          <button
              className="btn btn-sm btn-success mx-1"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <i className="fa fa-check"></i>
            </button>
            <button className="btn btn-sm btn-outline-danger">
              <i
                className="fa fa-exclamation-triangle"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              ></i>
            </button>
          <button className='btn btn-sm btn-danger mx-1' onClick={(e) => {
            e.stopPropagation();
            handleDeleteClick(row.id);
          }}><i className='fa fa-trash'></i></button>

        </div>
      )
    }
  ];
  const handleModalClick = () => {
    setIsOpen((prev) => !prev);
  }

  const handleEditClick = (id: number) => {
    setOpenModal(2); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  }

  const handleDeleteClick = (id: number) => {
    setOpenModal(3); // this opens the edit modal
    setSelectedId(id);
    setIsOpen(true);
  }

  let modalId;
  let modalTitle;
  let ModalToRender;

  switch (openModal) {
    case 2:
      modalId = 'edit-bid-analysis';
      modalTitle = 'Edit Bid ';
      ModalToRender = EditBidAnalysis

      break;
    case 3:
      modalId = 'delete-bid';
      modalTitle = '';
      ModalToRender = ConfirmDelete
      break;
    default:
      modalId = 'add-new-bid-analysis';
      modalTitle = 'Add New Bid';
      ModalToRender = AddBidAnalysis
      break;
  }

  const handleFilter = () => {}

  return loading ? <EllipsisLoader /> : (
    <>
      <ModalSwitcher isOpen={isOpen} setIsOpen={setIsOpen} id={modalId} title={modalTitle} component={<ModalToRender id={selectedId} closeModal={handleModalClick} path={`bid-analysis/${selectedId}/delete`} refresh={getBidAnalysis} />} />

      <div className="col-xl-12 mb-30">
        <div className="card card-statistics h-100">
          <div className="card-body">
            <div className="d-block d-md-flex justify-content-between">
              <div className="d-block z-10">
                  <InputSelect
                    data={formatRequisitionSelectData(requisitions)}
                    id="filter "
                    label="Filter By Requisition"
                    name="purchase_requisition_id"
                    required
                    handleChange={handleFilter}
                    errorMessage="Please select the requisitions"
                   // error={purchaseOrder.purchase_requisition_id.error}
                  />
              </div>
              <div className="d-block d-md-flex clearfix sm-mt-20">
                <div className="widget-search ml-0 clearfix">
                  
                </div>
              </div>
            </div>
            {/* table */}
            <MyDataTable columns={columns} data={quotations} title="" />
            {/* end table */}
          </div>
        </div>
      </div>
    </>
  )
}

export default ListBidAnalysis