import React, { useEffect, useState } from 'react'
import DynamicForm from '../../global/DynamicForm';
import Input from '../../global/Input';
import InputDate from '../../global/InputDate';
import InputSelect from '../../global/InputSelect';
import Textarea from '../../global/Textarea';
import { bidDefaultData, ERRORMESSAGE, errorToastOptions, successToastOptions } from '../../../constants';
import useCustomers from '../../../hooks/useCustomers';
import {  formatRequisitionSelectData, formatSelectData } from '../../../utils/helpers';
import Validator from '../../../utils/Validator';
import { formatErrorMessage, isValidDeliveryDate } from '../../../helpers';
import { doCreate } from '../../../api';
import toast from 'react-hot-toast';
import useSuppliers from '../../../hooks/useSuppliers';
import useRequisition from '../../../hooks/useRequisition';

interface IAddBidProps {
  onCancel: () => void;
}

interface selectedRequisition {
  items: item[]
}

interface item {
  label: string;
  value: number | string
}

const AddBid = ({ onCancel }: IAddBidProps) => {
  const [bid, setBid] = useState(bidDefaultData)
  const defaultData = [{ item_id: "", rate: "", quantity: "", total: "" }];
  const [values, setValues] = useState<Array<any>>(defaultData);
  const {loading, customers, refresh } = useCustomers()
  const { requisitions } = useRequisition();
  const [selectedRequisition, setSelectedRequisition] = useState<selectedRequisition>()

  const { suppliers } = useSuppliers();
  
  const validator = new Validator();
  
  useEffect(()=>console.log(customers), [])

  const handleChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target as HTMLInputElement;

    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setBid({
        ...bid,
        ...{
          [name]: { ...bid[name], ...{ error: false, data: value } },
        },
      });
    } else {
      // indicate an error
      setBid({
        ...bid,
        ...{
          [name]: { ...bid[name], ...{ error: true, data: value } },
        },
      });
    }
  };

  const handleSelectChange = (e: any) => {
    console.log('event', e)
    const selectedRequisition = requisitions.filter((req: {id: number})=> req.id === e.value);
    console.log(selectedRequisition, 'qaz')
    setSelectedRequisition(selectedRequisition[0])
    const { name, value } = e;
    // check if there is something in the text box
    if (value !== "") {
      // update the state
      setBid({
        ...bid,
        ...{
          [name]: { ...bid[name], ...{ error: false, data: value } },
        },
      });
    } else {
      // indicate an error
      setBid({
        ...bid,
        ...{
          [name]: { ...bid[name], ...{ error: true, data: value } },
        },
      });
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    if (validator.checkForEmptyFields(bid, setBid)) {
      const isValid = isValidDeliveryDate(bid.issue_date.data, bid.expiry_date.data)
      if(isValid){
        //setLoading(true);
        const data = {
          issue_date: bid.issue_date.data,
          expiry_date: bid.expiry_date.data,
          reference: bid.reference_no.data,
          vendor_id: bid.vendor_id.data,
          description: bid.description.data,
          purchase_requisition: bid.purchase_requisition.data,
          status: "Pending",
          items: values,
        };

        doCreate("bids", data)
          .then((response) => {
            if (response.data.success === true) {
              toast.success(
                "Your bid has been successfully submitted",
                successToastOptions
              );
              refresh();

              setTimeout(() => {
                onCancel();
              }, 1000);
            } else {
              toast.error(
                formatErrorMessage(response.data.data, response.data.message),
                errorToastOptions
              );
            }
          })
          .catch(() => {
            toast.error(ERRORMESSAGE, errorToastOptions);
          })
          .finally(() => {
            //setLoading(false);
          });
      }else{
        toast.error('The expiry date must be later than the issue date.', errorToastOptions);
      }
    }
  };

  return (
    <form>
      <div className="form-row">
        <div className="col-sm-4">
          <InputDate
            name="issue_date"
            label="Issue Date"
            handleChange={handleSelectChange}
            required
            //value={bid.issue_date.data}
            //error={bid.issue_date.error}
            errorMessage="Please select the issue date"
          />
        </div>
        <div className="col-sm-4">
          <InputDate
            name="expiry_date"
            label="Expiry Date"
            handleChange={handleSelectChange}
            required
            //value={bid.expiry_date.data}
            //error={bid.expiry_date.error}
            errorMessage="Please select the expiry date"
          />
        </div>
        <div className="col-sm-4">
          <InputSelect
            data={formatRequisitionSelectData(requisitions)}
            id="purchase_requisition"
            name="purchase_requisition"
            label="Purchase Requisition"
            handleChange={handleSelectChange}
            //value={bid.purchase_requisition.data}
          />
        </div>
        <div className="col-sm-4">
          <InputSelect
            data={formatSelectData(suppliers)}
            id="purchase_requisition"
            name="vendor_id"
            label="Vendor Name(s)"
            handleChange={handleSelectChange}
            //value={bid.vendor_id.data}
          />
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-12">
          <Textarea
            handleChange={handleChange}
            name="description"
            label="Description"
            //value={bid.description.data}
            //error={bid.description.error}
            errorMessage="Please enter the bid description"
            required
          />
        </div>
      </div>

      <div className="form-row">
       {/* {selectedRequisition &&
         <bidDynamicForm
         defaultData={defaultData}
         setValues={setValues}
         values={values}
         requisitionItems={selectedRequisition?.items}
        />
       } */}
      </div>

      <div className="form-row m-0 pb-4 pt-3 justify-content-end">
        <button
          type="reset"
          className="btn btn-outline-danger mr-3"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="submit"
          disabled={loading}
          className="btn  btn-info"
          onClick={handleSubmit}
        >
          {loading ? "Creating..." : "Create"}
        </button>
      </div>
    </form>
  );
}

export default AddBid